
.annotatedSpan {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    line-height: 16px;
    transition: all 0.2s;

    /* These colors get set/overridden in the AnnotationSpan JavaScript. */
    --primary-color: transparent;
    --secondary-color: transparent;
}

.annotatedSpan svg {
    width: 18px;
    height: 18px;
}

.clickable {
    cursor: pointer;
    user-select: none;
}

.disabled {
    border: 1px solid #0E111759 !important;
    color: #0E111759 !important;
    background: transparent !important;
    cursor: not-allowed !important;
}

/* Topics */
.topic {
    background: var(--primary-color);
    border-radius: 4px;
    padding: 2px 4px;
}

/* Entities */
.entity {
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    padding: 0 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
    vertical-align: middle;
}
.entity.active {
    background: var(--primary-color);
    color: var(--secondary-color);
}
.entity.inactive {
    background: transparent;
    color: var(--primary-color);
}

/* Lemmas and Compound Components */
.lemma,          .compoundComponent          { border-radius: 4px; padding: 2px 4px; }
.lemma.active,   .compoundComponent.active   { background: var(--primary-color); }
.lemma.inactive, .compoundComponent.inactive { background: var(--secondary-color); }

/* Parts of Speech */
.posText {
    border-bottom: 3px solid var(--primary-color);
}
.posBucket {
    border: 2px solid var(--primary-color);
    border-radius: 4px;
    padding: 0 4px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
}
.posBucket.active {
    background: var(--primary-color);
    color: var(--secondary-color);
}
.posBucket.inactive {
    background: transparent;
    color: #0E111799;
}

/* Han Readings */
.hanReading.active {
    border-bottom: 3px solid var(--primary-color);
}
.hanReading.inactive {
    border-bottom: 3px solid var(--secondary-color);
}
.hanReading.active:hover {
    background: var(--primary-color);
    border-radius: 4px;
}
.hanReading.inactive:hover {
    background: var(--secondary-color);
    border-radius: 4px;
}
