
.categoryTabs {
    position: absolute;
    right: -20px;
    margin-top: 76px;
}

.toggle:last-child { box-shadow: 0 1px 2px 0 #0E111714; }

.toggle {
    background: var(--light-gray);
    line-height: 0;
    cursor: pointer;
    transition: all 0.2s;
}
.toggle svg { padding: 8px; }
.toggle path { fill: #0E111759; }

.toggle.selected { background: var(--light-blue); }
.toggle.selected path { fill: var(--dark-blue); }

.toggle:first-child { border-radius: 0 16px 0 0; }
.toggle:last-child  { border-radius: 0 0 16px 0; }
