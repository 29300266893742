
.unsupported {
    margin-left: -32px;
    margin-right: -32px;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.blurredDoc {
    padding-left: 32px;
    padding-right: 32px;
}

.blurEffect {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(120, 120, 120, 0.7) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.message {
    padding: 24px;
    margin: 0 40px;
    border-radius: 8px;
    background: var(--med-blue);
    box-shadow: 0 16px 38px 0 #0E111766;
}
