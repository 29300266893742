
.disclaimer {
    position: absolute;
    right: -20px;
    bottom: 31px;
    z-index: 1000;
    color: #0E111759;
}
.disclaimer:hover {
    color: black;
}
.disclaimer:hover .tab {
    color: black;
    background: #E3E8EF;
    box-shadow: -1px 2px 1px 0 #0E111733;
}
.disclaimer:hover .description {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
    background: #E3E8EF;
    box-shadow: -1px 2px 1px 0 #0E111733;
}

.tab {
    line-height: 0;
    transition: all 0.2s;
    background: #F0F2F5;
    border-radius: 0 38px 38px 0;
    box-shadow: 0 1px 2px 0 #0E111714;
}
.tab svg { padding: 8px; }

.description {
    box-sizing: border-box;
    position: absolute;
    width: 328px;
    right: 40px;
    bottom: 0;
    padding: 16px;
    background: #F0F2F5;
    box-shadow: 0 1px 0px 0 #0E111714;
    border-radius: 8px 8px 0 8px;

    /* Hover-related props */
    opacity: 0;
    pointer-events: none;
    transform: scale(0.7);
    transform-origin: bottom right;
    transition: all 0.2s;
}
