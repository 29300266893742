
.pageLayout {
    box-sizing: border-box;
    padding: 24px 32px 32px 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pageLayout > * {
    flex-grow: 0;
    flex-shrink: 0;
}

/* "Analyze" row */
.pageLayout > *:first-child {
    align-self: end;
}

/* "Text Area" row */
.pageLayout > *:nth-child(2) {
    flex-grow: 1;
    padding: 24px;
    background: #F0F2F5;
    border: 1px dashed #0E111759;
    border-radius: 16px;
    position: relative;
}

/* "Lang Select / Char Count" row */
.pageLayout > *:last-child {
    display: flex;
    justify-content: space-between;
}

.textArea {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    resize: none;
    caret-color: var(--med-blue);

    font-family: 'Noto Sans', 'Segoe UI', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.25px;
}

.docIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    color: #101A240D;
}

.helperText {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px;
    margin: 0 auto;
    left: 0;
    right: 0;
    max-width: 533px;
    text-align: center;
    pointer-events: none;
}
.helperText a {
    pointer-events: auto;
}