
.switch {
    /* Some variables that can be tweaked if desired. */
    --height: 40px;
    --width: 100px;
    --text-slide: 15px;

    height: var(--height);
    width: var(--width);
    position: relative;
    cursor: pointer;
    user-select: none;
}

/* Track */
.track {
    background: #D6EDFC;
    height: 100%;
    border-radius: calc(var(--height) / 2);
    text-align: center;
    transition: all 0.2s;
}
.switch.on .track {
    background: #A2D6FF;
}

/* Thumb */
.thumb {
    position: absolute;
    height: var(--height);
    width: var(--height); /* yes, "width" should be height, it's a circle :) */
    color: #FCFCFDF2;
    background: var(--med-blue);
    border-radius: calc(var(--height) / 2);
    transition: all 0.2s;
    z-index: 10;
    box-shadow: 0 4px 5px 0 #0E111740;
}
.switch.on .thumb {
    transform: translateX(calc(var(--width) - var(--height)));
}

/* Icon */
.thumb svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Text */
.switch .label {
    display: inline-block;
    line-height: var(--height);
    transform: translateX(var(--text-slide));
    color: #0E111780;
    transition: all 0.2s;
}
.switch.on .label {
    color: #0E1117F2;
    transform: translateX(calc(var(--text-slide) * -1));
}