
.wikiPreview {
    display: flex;
    width: 570px;
    border-radius: 8px;
    overflow: hidden;
}
.wikiPreview > *:first-child {
    flex: 1 1;
}
.wikiPreview > *:last-child {
    flex: 1 1;
}

.wikiPreview.vertical {
    flex-direction: column-reverse;
    width: 289px;
}
.wikiPreview.vertical > *:first-child {
    flex: 1 1;
}
.wikiPreview.vertical > *:last-child {
    flex: 1 1;
    max-height: 300px;
}
