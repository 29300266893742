
.drawerFlex {
    display: flex;
    margin: 8px 19px 0 16px;
    gap: 8px;
    flex-wrap: wrap;
}
.drawerFlex > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}