
.pageWrapper {
    text-align: center;
    padding: 32px;
    box-sizing: border-box;
    height: 100%;
    background: #F0F2F5;
}
.pageContents {
    box-sizing: border-box;
    border: 1px dashed #0E111759;
    border-radius: 18px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
