
:root {
  --white: #FCFCFD;
  --light-gray: #E8E8E8;
  --med-gray: #C2CBDA;
  --light-blue: #E7F5FF;
  --light-blue-link: #7CC6FF;
  --med-blue: #388CCD;
  --dark-blue: #2470AB;
  --med-red: #F14722;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', 'Segoe UI', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: transparent;
  overflow: hidden;
}

.documentText {
  line-height: 28px;
  letter-spacing: 0.25px;
}

/* SIMPLON NORM FONT IMPORTS */
/* mapping follows this https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping */
@font-face {
  font-family: 'Simplon Norm';
  src: url('../public/fonts/SimplonNorm-Bold.otf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Simplon Norm';
  src: url('../public/fonts/SimplonNorm-BoldItalic.otf');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Simplon Norm';
  src: url('../public/fonts/SimplonNorm-Medium.otf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Simplon Norm';
  src: url('../public/fonts/SimplonNorm-MediumItalic.otf');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Simplon Norm';
  src: url('../public/fonts/SimplonNorm-Regular.otf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Simplon Norm';
  src: url('../public/fonts/SimplonNorm-RegularItalic.otf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Simplon Norm';
  src: url('../public/fonts/SimplonNorm-Light.otf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Simplon Norm';
  src: url('../public/fonts/SimplonNorm-LightItalic.otf');
  font-style: italic;
  font-weight: 300;
}
