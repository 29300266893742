
.layoutWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.layoutBody {
    margin: 0 32px;
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;
    height: 100%;
}

.layoutHeading {
    margin-top: 24px;
}
.layoutContents > *:last-child {
    margin-bottom: 24px;
}
/* We need a little extra space at the bottom when there's a drawer or else the
    end of the document gets covered up even when the drawer is closed. */
.layoutContents.withDrawer > *:last-child {
    margin-bottom: 65px;
}

.layoutContents {
    flex-shrink: 1;
    flex-grow: 1;
    overflow: auto;
}
.layoutContents.withSeparator {
    padding-top: 10px;
}

.separator {
    margin-top: 18px;
    background: #D1D1D1;
    height: 1px;
    width: 100%;
}

.layoutDrawer {
    position: relative;
    background: #FCFCFDF2;
    border-top: 1px solid #C2CBDA;
    box-shadow: 0 -4px 6px -1px #0E111712;
}

/* Slide the drawer open */
.layoutDrawer {
    transform: translateY(-41px);
    transition: transform 0.3s;
}
.layoutDrawer.open {
    transform: translateY(-100%);
}

.layoutDrawer .drawerHeading {
    padding: 11px 13px;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    right: 0;
    overflow: hidden;
}

/* Show the content when open */
.layoutDrawer .drawerContent {
    padding: 16px 20px 28px 15px;
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;
}
.layoutDrawer.open .drawerContent {
    opacity: 1;
    pointer-events: auto;
}


.layoutDrawer .drawerTitle {
    display: inline-block;
    vertical-align: middle;
}

/* Rotate the arrow when open */
.layoutDrawer .drawerIcon {
    transition: transform 0.3s;
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    margin-left: 6px;
}
.layoutDrawer.open .drawerIcon {
    transform: rotate(180deg);
}
