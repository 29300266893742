
.pill {
    color: var(--med-blue);
    background-color: #388CCD1A;
    gap: 0;
    padding: 6px 12px;
    display: flex;
    border-radius: 16px;

    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
}

.icon {
    line-height: 20px;
    height: 20px;
}
.icon svg {
    margin-top: -2px;
}
.text {
    padding: 0 8px;
    display: inline-block;
    line-height: 20px;
    vertical-align: middle;
}