
.appLayout {
  box-sizing: border-box;
  height: 100vh;
  justify-content: center;
  border-radius: 24px;
  padding: 20px 0;
  background: #E8E8E8;
  display: flex;
}

.appColumn {
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Give the header a gray background and proper height. */
.appColumn > *:first-child {
  flex-basis: 56px;
  flex-shrink: 0;
}
