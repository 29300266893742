
.libLayout {
    width: 316px;
    height: 100%;
    position: relative;
    transition: all 0.3s;
}

.beam {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 20px;
    background: #E8E8E8;
    z-index: 1;
}
.closeColumn {
    position: absolute;
    left: 296px;
    transition: all 0.3s;
    cursor: pointer;
}
.libraryList {
    transition: all 0.3s;
    position: absolute;
    overflow: auto;
    background: #FFF;
    width: 276px;
    height: 100%;
    left: 20px;
    top: 0;
}
.closeColumn svg {
    position: relative;
    width: 20px;
    top: 50%;
    margin-top: -12px; /* Half the height */
}

/* Collapsed Styling */
.libLayout:not(.libraryOpen)                  { width: 20px; }
.libLayout:not(.libraryOpen) .libraryList     { transform: translateX(-100%); }
.libLayout:not(.libraryOpen) .closeColumn     { left: 0; }
.libLayout:not(.libraryOpen) .closeColumn svg { transform: rotate(180deg); }

.libEntry {
    border-bottom: 1px solid var(--med-gray);
    background: #101A240D;
    padding: 16px;
    cursor: pointer;
}
.libEntry     { transition: all 0.2s; }
.libEntry > * { transition: all 0.2s; }

.libEntry.selected {
    background: var(--white);
    box-shadow: 0px 4px 8px -2px #0E111714;
}

.docTitle {
    display: block;
}

.libEntry.disabled { cursor: not-allowed; }
.libEntry.disabled .docTitle { color: #0E111759; }
.libEntry.disabled .catLabel { color: #0E111759; border-color: #0E111759; }
