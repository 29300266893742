
.sentence {
    width: 100%;
}

.divider {
    border-bottom: 1px dashed #C2CBDA;
    width: 90%;
}
.divider:last-child {
    display: none;
}